import { Controller } from "@hotwired/stimulus"

import "select2"
import "select2/dist/css/select2"
import $ from 'jquery';

// Connects to data-controller="tags"
export default class extends Controller {
  connect() {
    //console.log("connect tags")
    //console.log(this.element)

    $(this.element).select2({
      theme: "foundation",
      //allowClear: true,
      multiple: true,
      tags: true,
      tokenSeparators: [',', ' '],
    })

  }

  disconnect() {
    //console.log("disconnect tags")
    //console.log(this.element)

    $(this.element).select2('destroy')
  }
}
