import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

import "select2"
import "select2/dist/css/select2"

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    const el = $(this.element)
    const options = { theme: 'foundation' }
    el.select2(options)
  }

  disconnect() {
    const el = $(this.element)
    el.select2('destroy')
  }
}
