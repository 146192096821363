// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'
//
// you can also import a translation file
import { Dutch } from 'flatpickr/dist/l10n/nl.js'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Dutch,
      allowInput: true,
    }
  }
}
