import { Turbo } from "@hotwired/turbo-rails"
import "./controllers"

import "./stylesheets/application"
import "./vendor"

import $ from 'jquery';
import EnterToSearch from "./src/enter_to_search"

document.addEventListener('turbo:load', function() {
  //console.log("event turbo:load")
  $(document).foundation()
  EnterToSearch.init()
})

document.addEventListener('turbo:before-cache', function() {
  //console.log("event turbo:before-cache")
})

document.addEventListener('turbo:before-render', function(event) {
  //console.log("event turbo:before-render")
  //console.log(event)
})
